
import { Options, Vue } from 'vue-class-component';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ErrorResponse from '@/models/ErrorResponse';
import { createMessageQueue, getMessageQueueStatus } from '@/services/api-services';

@Options({
  props: {
    token: String,
    messenger: Number,
  },
  validations: {
    phone: {
      required,
    },
    message: {
      required,
    },
  },
})
export default class TestMessageForm extends Vue {
  token!: string;

  messenger!: number;

  isLoading = false;

  serverErrors: ErrorResponse[] = [];

  messageStatusError: ErrorResponse[] = [];

  phone = '';

  message = '';

  forceSendSms = false;

  messageId = '';

  status = 0;

  statusTimer = 0;

  v$ = useVuelidate();

  async send(): Promise<void> {
    try {
      this.isLoading = true;
      this.messageId = '';
      this.serverErrors = [];
      this.messageStatusError = [];
      const response = await createMessageQueue(this.phone, this.message, this.forceSendSms, this.token);

      if (response.error) {
        this.serverErrors = [response.error];
      } else {
        console.log(response);
        this.messageId = response.requestId;
        this.status = response.status;
        this.statusTimer = setInterval(this.checkStatus, 1000);
      }
    } catch (ex) {
      this.serverErrors = [{
        code: 500,
        description: this.$t('common.serverError'),
      }];
    } finally {
      this.isLoading = false;
    }
  }

  async checkStatus(): Promise<void> {
    try {
      this.isLoading = true;
      this.serverErrors = [];
      const response = await getMessageQueueStatus(this.messageId);

      if (response.error) {
        this.status = response.status;
        this.messageStatusError = [response.error];
      } else {
        this.status = response.status;
      }
    } catch (ex) {
      this.serverErrors = [{
        code: 500,
        description: this.$t('common.serverError'),
      }];
      clearInterval(this.statusTimer);
    }

    if (this.status > 1) {
      this.isLoading = false;
      clearInterval(this.statusTimer);
    }
  }
}
