
import { Options, Vue } from 'vue-class-component';
import useVuelidate from '@vuelidate/core';
import { required, url } from '@vuelidate/validators';

interface RestApiConfig {
  method: string;
  url: string;
}

@Options({
  props: {
    smsApiConfig: String,
  },
  validations: {
    method: {
      required,
    },
    url: {
      required,
      url,
    },
  },
})
export default class RestApiForm extends Vue {
  smsApiConfig!: string;

  method = 'GET';

  url = '';

  v$ = useVuelidate();

  mounted(): void {
    if (this.smsApiConfig) {
      const config = JSON.parse(this.smsApiConfig) as RestApiConfig;
      this.method = config.method;
      this.url = config.url;
    }
  }

  onChange(): void {
    const config: RestApiConfig = {
      method: this.method,
      url: this.url,
    };
    const configStr = JSON.stringify(config);
    this.$emit('on-change', configStr);
  }
}
