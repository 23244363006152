
import { Options, Vue } from 'vue-class-component';
import { watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from './store';

const excludedAuthPath = ['/signup', '/login', '/forgot-password'];
@Options({
  components: {},
})
export default class App extends Vue {
  mounted(): void {
    const store = useStore();
    store.commit('initialiseStore');
    const route = useRoute();

    watchEffect(() => {
      const currentRouteName = route.path;
      if (currentRouteName !== '/') {
        if (!excludedAuthPath.includes(currentRouteName) && !store.state.token) {
          this.$router.push('/login');
        }
      }
    });

    if (store.state.language) {
      this.$i18n.locale = store.state.language;
    } else {
      let usersLanguage = window.navigator.language;
      if (usersLanguage.includes('-')) {
        usersLanguage = usersLanguage.split('-')[0].toLowerCase();
      }
      const availibleLocales = this.$i18n.availableLocales;
      if (availibleLocales.includes(usersLanguage)) {
        this.$i18n.locale = usersLanguage;
        store.commit('setLang', usersLanguage);
      } else {
        this.$i18n.locale = 'en';
      }
    }
  }
}
