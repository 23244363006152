
import { Options, Vue } from 'vue-class-component';
import LangSelector from '@/components/LangSelector.vue';
import { useStore } from '@/store';

@Options({
  components: {
    LangSelector,
  },
})
export default class TopPanel extends Vue {
  isOpenProfile = false;

  store = useStore();

  logout(): void {
    this.store.commit('clearAuth');
    this.$router.push('/login');
  }
}
