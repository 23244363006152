import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-xxl flex-grow-1 container-p-y" }
const _hoisted_2 = { class: "fw-bold py-3 mb-4" }
const _hoisted_3 = { class: "text-muted fw-light" }
const _hoisted_4 = { class: "row mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TarifCard = _resolveComponent("TarifCard")!
  const _component_YMoneyButton = _resolveComponent("YMoneyButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('common.billing')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_TarifCard),
      _createVNode(_component_YMoneyButton)
    ])
  ]))
}