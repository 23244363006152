
import { Options, Vue } from 'vue-class-component';
import Menu from '@/components/Menu.vue'; // @ is an alias to /src
import TopPanel from '@/components/TopPanel.vue';

@Options({
  components: {
    Menu,
    TopPanel,
  },
})
export default class MainLayout extends Vue {
}
