import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "card additianal-margin" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { id: "formConfig" }
const _hoisted_5 = { style: {"display":"flex","justify-content":"space-between","align-items":"center"} }
const _hoisted_6 = {
  class: "col-form-label",
  for: "configSelect"
}
const _hoisted_7 = ["value"]
const _hoisted_8 = {
  class: "col-form-label",
  for: "statusSelect"
}
const _hoisted_9 = { value: "-1" }
const _hoisted_10 = { value: "0" }
const _hoisted_11 = { value: "1" }
const _hoisted_12 = { value: "2" }
const _hoisted_13 = { value: "3" }
const _hoisted_14 = { value: "4" }
const _hoisted_15 = {
  for: "phoneFormControlSelect",
  class: "col-form-label"
}
const _hoisted_16 = ["placeholder"]
const _hoisted_17 = {
  for: "html5-date-from",
  class: "col-form-label"
}
const _hoisted_18 = {
  for: "html5-date-to",
  class: "col-form-label"
}
const _hoisted_19 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.$t('statistic.formTitle')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("form", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('message.config')), 1),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("select", {
                id: "configSelect",
                class: "form-select",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.config) = $event))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.configs, (conf) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: conf.id,
                    value: conf.id
                  }, _toDisplayString(_ctx.$filters.messengerFilter(conf.messenger)) + " - " + _toDisplayString(_ctx.$filters.smsApiFilter(conf.smsApi)) + " (" + _toDisplayString(conf.sender) + ") ", 9, _hoisted_7))
                }), 128))
              ], 512), [
                [_vModelSelect, _ctx.config]
              ])
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('statistic.status')), 1),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("select", {
                id: "statusSelect",
                class: "form-select",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.status.$model) = $event))
              }, [
                _createElementVNode("option", _hoisted_9, _toDisplayString(_ctx.$t('statistic.selectStatus')), 1),
                _createElementVNode("option", _hoisted_10, _toDisplayString(_ctx.$t('message.status.created')), 1),
                _createElementVNode("option", _hoisted_11, _toDisplayString(_ctx.$t('message.status.inQueue')), 1),
                _createElementVNode("option", _hoisted_12, _toDisplayString(_ctx.$t('message.status.sendMessenger')), 1),
                _createElementVNode("option", _hoisted_13, _toDisplayString(_ctx.$t('message.status.sendSmsApi')), 1),
                _createElementVNode("option", _hoisted_14, _toDisplayString(_ctx.$t('message.status.failed')), 1)
              ], 512), [
                [_vModelSelect, _ctx.v$.status.$model]
              ])
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('statistic.phone')), 1),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                id: "phoneFormControlSelect",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$.phone.$model) = $event)),
                placeholder: _ctx.$t('config.phone')
              }, null, 8, _hoisted_16), [
                [
                  _vModelText,
                  _ctx.v$.phone.$model,
                  void 0,
                  { trim: true }
                ]
              ])
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('statistic.from')), 1),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                class: "form-control",
                type: "date",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.from) = $event)),
                id: "html5-date-from"
              }, null, 512), [
                [_vModelText, _ctx.from]
              ])
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('statistic.to')), 1),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                class: "form-control",
                type: "date",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.to) = $event)),
                id: "html5-date-to"
              }, null, 512), [
                [_vModelText, _ctx.to]
              ])
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              class: "btn btn-primary d-grid w-100",
              type: "submit",
              onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.applyFilter()), ["prevent"])),
              disabled: _ctx.v$.$invalid
            }, _toDisplayString(_ctx.$t('statistic.applyFilter')), 9, _hoisted_19)
          ])
        ])
      ])
    ])
  ]))
}