const utils = {
  isTelegram(messanger: number): boolean {
    return messanger === 1;
  },

  isViber(messanger: number): boolean {
    return messanger === 2;
  },
};

export default utils;
