import AuthResponse from '@/models/AuthResponse';
import BaseResponse from '@/models/BaseResponse';
import ChangePasswordRequest from '@/models/ChangePasswordRequest';
import ChangeLanuageRequest from '@/models/ChangeLanuageRequest';
import Account from '@/models/Account';
import Config from '@/models/Config';
import ConfigRequest from '@/models/ConfigRequest';
import ConfirmUserEmailRequest from '@/models/ConfirmUserEmailRequest';
import LoginRequest from '@/models/LoginRequest';
import MessageQueue from '@/models/MessageQueue';
import MessageQueueFilter from '@/models/MessageQueueFilter';
import MessageResponse from '@/models/MessageResponse';
import NewPasswordRequest from '@/models/NewPasswordRequest';
import Page from '@/models/Page';
import RegisterRequest from '@/models/RegisterRequest';
import StatisticPerDays from '@/models/StatisticPerDays';
import StatisticProfitable from '@/models/StatisticProfitable';
import TotalStatistic from '@/models/TotalStatistic';
import { get, post, put } from '@/utils/http-utils';

export async function login(loginRequest: LoginRequest): Promise<BaseResponse<AuthResponse>> {
  const response = post<BaseResponse<AuthResponse>>('/api/auth/login', loginRequest);
  return response;
}

export async function register(registerReqeust: RegisterRequest): Promise<BaseResponse<AuthResponse>> {
  const response = await post<BaseResponse<AuthResponse>>('/api/auth/register', registerReqeust);
  return response;
}

export async function confirmEmail(confirmUserEmailRequest: ConfirmUserEmailRequest): Promise<BaseResponse<AuthResponse>> {
  const response = await post<BaseResponse<AuthResponse>>('/api/auth/confirm', confirmUserEmailRequest);
  return response;
}

export async function newPassword(newPasswordReqeust: NewPasswordRequest): Promise<BaseResponse<AuthResponse>> {
  const response = await post<BaseResponse<AuthResponse>>('/api/auth/new-password', newPasswordReqeust);
  return response;
}

export async function changePassword(changePasswordReqeust: ChangePasswordRequest): Promise<BaseResponse<boolean>> {
  const response = await post<BaseResponse<boolean>>('/api/auth/change-password', changePasswordReqeust);
  return response;
}

export async function fetchConfigs(): Promise<BaseResponse<Config[]>> {
  const response = await post<BaseResponse<Config[]>>('/api/config/list', {});
  return response;
}

export async function getConfig(id: string): Promise<BaseResponse<Config>> {
  const response = await get<BaseResponse<Config>>(`/api/config/${id}`);
  return response;
}

export async function createConfig(configRequest: ConfigRequest): Promise<BaseResponse<Config>> {
  const response = await post<BaseResponse<Config>>('/api/config', configRequest);
  return response;
}

export async function updateConfig(configRequest: ConfigRequest): Promise<BaseResponse<Config>> {
  const response = await put<BaseResponse<Config>>('/api/config', configRequest);
  return response;
}

export async function fetchMessageQueues(messageQueueFilter: MessageQueueFilter): Promise<BaseResponse<Page<MessageQueue[]>>> {
  const response = await post<BaseResponse<Page<MessageQueue[]>>>('/api/message/list', messageQueueFilter);
  return response;
}

export async function createMessageQueue(phone: string, message: string, forceSendSms: boolean, token: string): Promise<MessageResponse> {
  const response = await get<MessageResponse>(`/api/v1/message/send?phone=${phone}&msg=${message}&forceSendSms=${forceSendSms}&token=${token}`);
  return response;
}

export async function getMessageQueueStatus(messageId: string): Promise<MessageResponse> {
  const response = await get<MessageResponse>(`/api/v1/message/status?requestId=${messageId}`);
  return response;
}

export async function getStatisticPerDay(): Promise<BaseResponse<StatisticPerDays>> {
  const response = await get<BaseResponse<StatisticPerDays>>('/api/message/statistic/perdays');
  return response;
}

export async function getTotalStatistic(): Promise<BaseResponse<TotalStatistic>> {
  const response = await get<BaseResponse<TotalStatistic>>('/api/message/statistic/total');
  return response;
}

export async function getStatisticProfitable(): Promise<BaseResponse<StatisticProfitable>> {
  const response = await get<BaseResponse<StatisticProfitable>>('/api/message/statistic/profitable');
  return response;
}

export async function changeLanguage(accountId: string, changeLanuageRequest: ChangeLanuageRequest): Promise<BaseResponse<Account>> {
  const response = await put<BaseResponse<Account>>(`/api/account/${accountId}/lang`, changeLanuageRequest);
  return response;
}
