
import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';

@Options({
  components: {},
  props: {},
})
export default class TarifCard extends Vue {
  store = useStore();

  calculeteNextCount(tarif: number): number {
    if (tarif === 0) {
      return 200;
    }
    if (tarif === 1) {
      return 1000;
    }
    if (tarif === 2) {
      return 5000;
    }
    if (tarif === 3) {
      return 10000;
    }

    return 200;
  }
}
