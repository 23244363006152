
import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';
import { fetchConfigs } from '@/services/api-services';
import Config from '@/models/Config';
import MessagesStatisticCard from '@/components/MessagesStatisticCard.vue';
import ProfitableCard from '@/components/ProfitableCard.vue';
import BalanceSmallCard from '@/components/BalanceSmallCard.vue';
import TarifSmallCard from '@/components/TarifSmallCard.vue';
import MessagesStatisticChart from '@/components/MessagesStatisticChart.vue';

@Options({
  components: {
    MessagesStatisticCard,
    ProfitableCard,
    MessagesStatisticChart,
    BalanceSmallCard,
    TarifSmallCard,
  },
})
export default class Dashboard extends Vue {
  store = useStore();

  configs: Config[] = [];

  async mounted(): Promise<void> {
    await this.loadConfigs();
  }

  async loadConfigs(): Promise<void> {
    const response = await fetchConfigs();
    if (response.isSuccess && response.data) {
      this.configs = response.data;
      this.store.commit('setConfigs', response.data);
    }
  }

  goToConfiguration(): void {
    this.$router.push('/configs');
  }
}
