import AuthResponse from '@/models/AuthResponse';
import BaseResponse from '@/models/BaseResponse';
import RefreshTokenRequest from '@/models/RefreshTokenRequest';
import { store } from '@/store';

// const serverUrl = 'http://localhost:5044'; // TODO: dev only
// const serverUrl = 'http://api.infocodebot.local';
const serverUrl = 'https://api.infocodebot.com';

interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

export async function refreshToken(): Promise<string> {
  const refreshTokentRequest: RefreshTokenRequest = {
    refreshToken: store.state.refreshToken,
  };

  const args: RequestInit = {
    method: 'post',
    body: JSON.stringify(refreshTokentRequest),
    headers: { 'Content-Type': 'application/json' },
  };
  const request = new Request(`${serverUrl}/api/auth/refresh`, args);

  const response: HttpResponse<BaseResponse<AuthResponse>> = await fetch(
    request,
  );

  if (!response.ok) {
    if (response.status === 401) {
      // TODO: redirect to auth page
    } else if (response.status === 400) {
      const errorResponse = await response.json() as BaseResponse<AuthResponse>;
      let error = 'unknown';
      if (errorResponse.errors && errorResponse.errors.length > 0) {
        error = errorResponse.errors[0].description;
      }
      throw new Error(error);
    }
    throw new Error(response.statusText);
  }

  let parsedBody = null;
  try {
    // may error if there is no body
    parsedBody = await response.json();
  } catch (ex) {
    throw new Error('Error parse json');
  }

  store.commit('setAuth', parsedBody.data);

  return parsedBody.data.authToken;
}

export async function http<T>(
  request: RequestInfo,
): Promise<T> {
  let response: HttpResponse<T> = await fetch(
    request,
  );

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshToken();
      const originalRequest = request as Request;
      const args = {
        method: originalRequest.method,
        body: originalRequest.body,
        headers: { Authorization: `Bearer ${newToken}` },
      };
      response = await fetch(new Request(originalRequest.url, args));

      if (!response.ok) {
        throw new Error(response.statusText);
      }
    } else if (response.status !== 400) {
      throw new Error(response.statusText);
    }
  }

  let parsedBody = null;
  try {
    // may error if there is no body
    parsedBody = await response.json();
  } catch (ex) {
    throw new Error('Error parse json');
  }

  return parsedBody;
}

export async function get<T>(
  path: string,
): Promise<T> {
  const args: RequestInit = {
    method: 'get',
    headers: {
      Authorization: `Bearer ${store.state.token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await http<T>(new Request(`${serverUrl}${path}`, args));
  return response;
}

export async function post<T>(
  path: string,
  body: any,
): Promise<T> {
  const args: RequestInit = {
    method: 'post',
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${store.state.token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await http<T>(new Request(`${serverUrl}${path}`, args));
  return response;
}

export async function put<T>(
  path: string,
  body: any,
): Promise<T> {
  const args: RequestInit = {
    method: 'put',
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${store.state.token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await http<T>(new Request(`${serverUrl}${path}`, args));
  return response;
}
