import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "formConfig" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = {
  for: "emailFormControlInput",
  class: "form-label"
}
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = {
  for: "passwordFormControlInput",
  class: "form-label"
}
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = {
  for: "fromFormControlInput",
  class: "form-label"
}
const _hoisted_10 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('config.smsAero.email')), 1),
      _withDirectives(_createElementVNode("input", {
        type: "email",
        class: "form-control",
        id: "emailFormControlInput",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.email.$model) = $event)),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChange())),
        placeholder: _ctx.$t('config.smsAero.emailPlaceholder')
      }, null, 40, _hoisted_4), [
        [
          _vModelText,
          _ctx.v$.email.$model,
          void 0,
          { trim: true }
        ]
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('config.smsAero.password')), 1),
      _withDirectives(_createElementVNode("input", {
        type: "password",
        class: "form-control",
        id: "passwordFormControlInput",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$.password.$model) = $event)),
        onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onChange())),
        placeholder: _ctx.$t('config.smsAero.passwordPlaceholder')
      }, null, 40, _hoisted_7), [
        [
          _vModelText,
          _ctx.v$.password.$model,
          void 0,
          { trim: true }
        ]
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('config.smsAero.from')), 1),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "form-control",
        id: "fromFormControlInput",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.v$.from.$model) = $event)),
        onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onChange())),
        placeholder: _ctx.$t('config.smsAero.fromPlaceholder')
      }, null, 40, _hoisted_10), [
        [
          _vModelText,
          _ctx.v$.from.$model,
          void 0,
          { trim: true }
        ]
      ])
    ])
  ]))
}