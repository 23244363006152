
import LoginRequest from '@/models/LoginRequest';
import LangSelector from '@/components/LangSelector.vue';
import ErrorResponse from '@/models/ErrorResponse';
import { useStore } from '@/store';
import { login } from '@/services/api-services';
import { Options, Vue } from 'vue-class-component';
import useVuelidate from '@vuelidate/core';
import { required, minLength, email } from '@vuelidate/validators';

@Options({
  components: {
    LangSelector,
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },
})
export default class Login extends Vue {
  isLoading = false;

  email = '';

  password = '';

  serverErrors: ErrorResponse[] = [];

  v$ = useVuelidate();

  store = useStore();

  async signIn(): Promise<void> {
    const loginRequest: LoginRequest = {
      email: this.email as string,
      password: this.password as string,
    };

    try {
      this.isLoading = true;
      this.serverErrors = [];
      const response = await login(loginRequest);
      if (response.isSuccess && response.data) {
        console.log(response.data);

        this.store.commit('setAuth', response.data);
        this.$router.push({ name: 'Dashboard' });
      } else if (response.errors) {
        this.serverErrors = response.errors;
      }
    } catch (ex) {
      this.serverErrors = [{
        code: 500,
        description: this.$t('common.serverError'),
      }];
    } finally {
      this.isLoading = false;
    }
  }
}
