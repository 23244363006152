
import { Options, Vue } from 'vue-class-component';
import constants from '@/utils/constants';
import { useStore } from '@/store';
import ErrorResponse from '@/models/ErrorResponse';
import { getStatisticProfitable } from '@/services/api-services';
import StatisticProfitable from '@/models/StatisticProfitable';

@Options({
  components: {},
  props: {},
})
export default class ProfitableCard extends Vue {
  profitableReportChartConfig: any;

  store = useStore();

  profitableSeries: any[] = [
    {
      data: [],
    },
  ];

  profitableStatistic: StatisticProfitable = {
    profitableSum: 0,
    profitablePercent: 0,
    profitableWeekly: [],
  };

  isLoading = false;

  serverErrors: ErrorResponse[] = [];

  created(): void {
    this.buildChart();
  }

  async mounted(): Promise<void> {
    this.$nextTick(() => {
      window.dispatchEvent(new Event('resize'));
    });
    await this.loadData();
  }

  async loadData(): Promise<void> {
    try {
      this.isLoading = true;
      this.serverErrors = [];
      const response = await getStatisticProfitable();

      if (!response.isSuccess) {
        this.serverErrors = response.errors ? response.errors : [];
      } else {
        console.log(response);
        if (response.data) {
          this.profitableStatistic = response.data;
          this.profitableSeries = [
            {
              name: this.$t('statistic.moneyPerWeek'),
              data: response.data.profitableWeekly,
            },
          ];
        }
      }
    } catch (ex) {
      this.serverErrors = [{
        code: 500,
        description: this.$t('common.serverError'),
      }];
    } finally {
      this.isLoading = false;
    }
  }

  buildChart(): void {
    this.profitableReportChartConfig = {
      chart: {
        height: 80,
        // width: 175,
        type: 'line',
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          top: 10,
          left: 5,
          blur: 3,
          color: constants.colors.success,
          opacity: 0.15,
        },
        sparkline: {
          enabled: true,
        },
      },
      grid: {
        show: false,
        padding: {
          right: 8,
        },
      },
      colors: [constants.colors.success],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 5,
        curve: 'smooth',
      },
      xaxis: {
        show: false,
        lines: {
          show: false,
        },
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
    };
  }
}
