import moment from 'moment';

const filters = {
  messengerFilter(value: number): string {
    switch (value) {
      case 1:
        return 'Telegram';
      case 2:
        return 'Viber';
      case 3:
        return 'WhatsApp';
      default:
        return 'Unknown';
    }
  },

  smsApiFilter(value: number): string {
    switch (value) {
      case 0:
        return 'None';
      case 1:
        return 'REST';
      case 2:
        return 'Twillio';
      case 3:
        return 'SmsAero';
      case 4:
        return 'SmsCenter';
      default:
        return 'None';
    }
  },

  statusFilter(value: number): string {
    switch (value) {
      case 1:
        return 'Active';
      case 2:
        return 'Inactive';
      case 3:
        return 'Deleted';
      default:
        return 'Unknown';
    }
  },

  messageStatusFilter(value: number): string {
    switch (value) {
      case 0:
        return 'Created';
      case 1:
        return 'In Queue';
      case 2:
        return 'Send Messenger';
      case 3:
        return 'Send SMS';
      case 4:
        return 'Failed';
      default:
        return '';
    }
  },

  currencyFilter(value: number): string {
    switch (value) {
      case 643:
        return 'RUB';
      case 840:
        return 'USD';
      case 784:
        return 'AED';
      default:
        return 'USD';
    }
  },

  tarifFilter(value: number): string {
    switch (value) {
      case 0:
        return 'Free';
      case 1:
        return 'Small';
      case 2:
        return 'Medium';
      case 3:
        return 'Large';
      default:
        return 'Free';
    }
  },

  dateFormat(date: Date) {
    return moment(date).format('DD.MM.YYYY HH:mm:ss');
  },
};

export default filters;
