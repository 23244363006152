import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-8 col-lg-8 mb-4" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "row row-bordered g-0" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card-header m-0 me-2 pb-3" }
const _hoisted_6 = {
  id: "totalRevenueChart",
  class: "px-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.$t('statistic.byDayTitle')), 1),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_apexchart, {
              height: "350",
              type: "area",
              options: _ctx.messagesChartConfig,
              series: _ctx.messageStatisticsSeries
            }, null, 8, ["options", "series"])
          ])
        ])
      ])
    ])
  ]))
}