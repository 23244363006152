import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container-xxl flex-grow-1 container-p-y" }
const _hoisted_2 = { class: "fw-bold py-3 mb-4" }
const _hoisted_3 = { class: "text-muted fw-light" }
const _hoisted_4 = { style: {"margin":"0 0 10px 0"} }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "row mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfigCard = _resolveComponent("ConfigCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('common.configTitle')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createNewConfig()))
      }, _toDisplayString(_ctx.$t('config.create')), 1)
    ]),
    (_ctx.configs.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('config.empty')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.configs, (config) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-md-6 col-lg-4",
          key: config.id
        }, [
          _createVNode(_component_ConfigCard, {
            config: config,
            onUpdated: _ctx.onUpdateConfig
          }, null, 8, ["config", "onUpdated"])
        ]))
      }), 128))
    ])
  ]))
}