import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-xxl flex-grow-1 container-p-y" }
const _hoisted_2 = { class: "fw-bold py-3 mb-4" }
const _hoisted_3 = { class: "text-muted fw-light" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "table-responsive text-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageStatisticFilterForm = _resolveComponent("MessageStatisticFilterForm")!
  const _component_MessagesStatisticTable = _resolveComponent("MessagesStatisticTable")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('common.messageQueueStatistic')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_MessageStatisticFilterForm, {
        initConfig: _ctx.configId,
        initFrom: _ctx.formatDate(_ctx.from),
        initTo: _ctx.formatDate(_ctx.to),
        onApplyFilter: _ctx.applyFilter
      }, null, 8, ["initConfig", "initFrom", "initTo", "onApplyFilter"]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          _createVNode(_component_MessagesStatisticTable, {
            messages: _ctx.pageMessegeQueue.items,
            isLoading: _ctx.isLoading
          }, null, 8, ["messages", "isLoading"]),
          (_ctx.pageMessegeQueue.items.length > 0)
            ? (_openBlock(), _createBlock(_component_Pagination, {
                key: 0,
                totalPages: _ctx.totalPages,
                currentPage: _ctx.currentPage,
                onSetPage: _ctx.setPage
              }, null, 8, ["totalPages", "currentPage", "onSetPage"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}