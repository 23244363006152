
import LangSelector from '@/components/LangSelector.vue';
import RegisterRequest from '@/models/RegisterRequest';
import ErrorResponse from '@/models/ErrorResponse';
import { register } from '@/services/api-services';
import { useStore } from '@/store';
import { Options, Vue } from 'vue-class-component';
import useVuelidate from '@vuelidate/core';
import {
  required,
  minLength,
  email,
} from '@vuelidate/validators';

@Options({
  components: {
    LangSelector,
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
    terms: {
      checked: (val: boolean) => val, // add this line in validation
    },
  },
})
export default class Register extends Vue {
  isLoading = false;

  name = '';

  email = '';

  password = '';

  terms = false;

  serverErrors: ErrorResponse[] = [];

  v$ = useVuelidate();

  store = useStore();

  async signUp(): Promise<void> {
    const registerReqeust: RegisterRequest = {
      email: this.email as string,
      name: this.name as string,
      password: this.password as string,
      language: this.$i18n.locale,
      currency: this.getCurrency(),
    };

    try {
      this.isLoading = true;
      this.serverErrors = [];
      const response = await register(registerReqeust);
      if (response.isSuccess && response.data) {
        console.log(response.data);

        this.store.commit('setAuth', response.data);
        this.$router.push({ name: 'Dashboard' });
      } else if (response.errors) {
        this.serverErrors = response.errors;
      }
    } catch (ex) {
      this.serverErrors = [{
        code: 500,
        description: this.$t('common.serverError'),
      }];
    } finally {
      this.isLoading = false;
    }
  }

  getCurrency(): number {
    switch (this.$i18n.locale) {
      case 'ru':
        return 643;
      case 'en':
        return 840;
      default:
        return 840;
    }
  }
}
