import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Login from '@/views/Login.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import Register from '@/views/Register.vue';
import ConfirmEmail from '@/views/ConfirmEmail.vue';
import NewPassword from '@/views/NewPassword.vue';
import MainLayout from '@/views/MainLayout.vue';
import Dashboard from '@/views/Dashboard.vue';
import Configs from '@/views/Configs.vue';
import MessageQueueStatistic from '@/views/MessageQueueStatistic.vue';
import ConfigForm from '@/views/ConfigForm.vue';
import Payments from '@/views/Payments.vue';
import Documentation from '@/views/Documentation.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/signup',
    name: 'signup',
    component: Register,
  },
  {
    path: '/confirm/:email/:hash',
    name: 'confirm',
    component: ConfirmEmail,
  },
  {
    path: '/new-password/:email/:hash',
    name: 'new-password',
    component: NewPassword,
  },
  {
    path: '',
    component: MainLayout,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: '/configs',
        name: 'Configs',
        component: Configs,
      },
      {
        path: '/statistics',
        name: 'Statistics',
        component: MessageQueueStatistic,
      },
      {
        path: '/documentation',
        name: 'Documentation',
        component: Documentation,
      },
      {
        path: '/payments',
        name: 'Payments',
        component: Payments,
      },
      {
        path: '/configs/form/:type/:id',
        name: 'ConfigForm',
        component: ConfigForm,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
