
import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';
import useVuelidate from '@vuelidate/core';
import MessageQueueFilter from '@/models/MessageQueueFilter';
import Config from '@/models/Config';
import { Watch } from 'vue-property-decorator';

@Options({
  props: {
    initConfig: String,
    initFrom: String,
    initTo: String,
  },
  validations: {
    config: {},
    status: {},
    phone: {},
    from: {},
    to: {},
  },
})
export default class MessageStatisticFilterForm extends Vue {
  store = useStore();

  configs: Config[] = [];

  config = '';

  phone = '';

  status = '';

  from = '';

  to = '';

  initConfig!: string;

  initFrom!: string;

  initTo!: string;

  v$ = useVuelidate();

  mounted(): void {
    this.configs = this.store.state.configs;
    this.config = this.initConfig;
    this.from = this.initFrom;
    this.to = this.initTo;
  }

  @Watch('initConfig')
  onChildTotalPages(val: string, oldVal: string) {
    this.config = val;
  }

  applyFilter(): void {
    console.log(this.status);
    console.log(this.from);
    console.log(this.to);
    const messageFilter: MessageQueueFilter = {
      configId: this.config,
      skip: 0,
      take: 50,
      sortColumn: 'created',
      sortDirectionEnum: 0,
    };
    if (this.phone) {
      messageFilter.phoneNumber = this.phone;
    }

    if (this.status) {
      const statusNum = parseInt(this.status, 10);
      if (statusNum >= 0) {
        messageFilter.messageStatus = statusNum;
      }
    }

    if (this.from) {
      messageFilter.from = new Date(this.from);
    }

    if (this.to) {
      messageFilter.to = new Date(this.to);
    }
    this.$emit('applyFilter', messageFilter);
  }
}
