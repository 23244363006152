
import LangSelector from '@/components/LangSelector.vue';
import ConfirmUserEmailRequest from '@/models/ConfirmUserEmailRequest';
import ErrorResponse from '@/models/ErrorResponse';
import { Options, Vue } from 'vue-class-component';
import { confirmEmail } from '@/services/api-services';

@Options({
  components: {
    LangSelector,
  },
})
export default class ConfirmEmail extends Vue {
  isSuccessConfirm = false;

  isLoading = true;

  email = '';

  serverErrors: ErrorResponse[] = [];

  async mounted(): Promise<void> {
    const confirmUserEmailRequest: ConfirmUserEmailRequest = {
      email: this.$route.params.email as string,
      hash: this.$route.params.hash as string,
    };

    try {
      this.isLoading = true;
      this.serverErrors = [];
      const response = await confirmEmail(confirmUserEmailRequest);
      if (response.isSuccess && response.data) {
        console.log(response.data);
        this.isSuccessConfirm = true;
      } else if (response.errors) {
        this.serverErrors = response.errors;
      }
    } catch (ex) {
      this.isSuccessConfirm = true;
      this.serverErrors = [{
        code: 500,
        description: this.$t('common.serverError'),
      }];
    } finally {
      this.isLoading = false;
    }
  }
}
