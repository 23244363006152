
import MessageQueue from '@/models/MessageQueue';
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    messages: Array,
    isLoading: Boolean,
  },
  components: {
  },
})
export default class MessagesStatisticTable extends Vue {
  messages!: MessageQueue[];

  isLoading!: boolean;
}
