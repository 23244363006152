
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Options({
  props: {
    totalPages: Number,
    currentPage: Number,
  },
})
export default class Pagination extends Vue {
  totalPages!: number;

  currentPage!: number;

  pages: number[] = [];

  mounted(): void {
    for (let i = 0; i < this.totalPages; i++) {
      this.pages.push(i + 1);
    }
  }

  @Watch('totalPages')
  onChildTotalPages(val: number, oldVal: number) {
    this.pages = [];
    for (let i = 0; i < val; i++) {
      this.pages.push(i + 1);
    }
  }

  firstPage(): void {
    this.setPage(0);
  }

  lastPage(): void {
    this.setPage(this.totalPages - 1);
  }

  nextPage(): void {
    if (this.currentPage + 1 < this.totalPages) {
      this.setPage(this.currentPage + 1);
    }
  }

  prevPage(): void {
    if (this.currentPage - 1 >= 0) {
      this.setPage(this.currentPage - 1);
    }
  }

  setPage(page: number): void {
    this.$emit('setPage', page);
  }
}
