
import { Options, Vue } from 'vue-class-component';
import constants from '@/utils/constants';
import { useStore } from '@/store';
import ErrorResponse from '@/models/ErrorResponse';
import { getStatisticPerDay } from '@/services/api-services';
import TotalStatistic from '@/models/TotalStatistic';
import MessagesPerDay from '@/models/MessagesPerDay';

@Options({
  components: {},
  props: {},
})
export default class MessagesStatisticChart extends Vue {
  messagesChartConfig: any;

  store = useStore();

  messageStatisticsSeries: any[] = [];

  totalStatistic: TotalStatistic = {
    countTotal: 0,
    countByMessenger: 0,
    countBySms: 0,
    countFailed: 0,
  };

  isLoading = false;

  serverErrors: ErrorResponse[] = [];

  created(): void {
    this.messageStatisticsSeries = [];
    this.buildChart();
  }

  async mounted(): Promise<void> {
    this.$nextTick(() => {
      window.dispatchEvent(new Event('resize'));
    });
    await this.loadData();
  }

  async loadData(): Promise<void> {
    try {
      this.isLoading = true;
      this.serverErrors = [];

      const response = await getStatisticPerDay();

      if (!response.isSuccess) {
        this.serverErrors = response.errors ? response.errors : [];
      } else if (response.data) {
        const days = response.data.sendByMessenger.map((cm: MessagesPerDay) => cm.day.toString());
        this.messagesChartConfig = {
          ...this.messagesChartConfig,
          xaxis: {
            categories: [...days],
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: true,
            },
            labels: {
              show: true,
              style: {
                fontSize: '13px',
                colors: constants.colors.axisColor,
              },
            },
          },
        };
        this.messageStatisticsSeries = [
          {
            name: this.$t('statistic.sendByMessenger'),
            data: response.data.sendByMessenger.map((cm: MessagesPerDay) => cm.count),
          },
          {
            name: this.$t('statistic.sendBySms'),
            data: response.data.sendBySms.map((cm: MessagesPerDay) => cm.count),
          },
          {
            name: this.$t('statistic.sendErrors'),
            data: response.data.failedSending.map((cm: MessagesPerDay) => cm.count),
          },
        ];
      }
    } catch (ex) {
      this.serverErrors = [{
        code: 500,
        description: this.$t('common.serverError'),
      }];
    } finally {
      this.isLoading = false;
    }
  }

  buildChart(): void {
    this.messagesChartConfig = {
      chart: {
        // height: 215,
        parentHeightOffset: 0,
        parentWidthOffset: 0,
        toolbar: {
          show: true,
        },
        // type: 'area',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: 'smooth',
      },
      legend: {
        show: false,
      },
      colors: [constants.colors.primary, constants.colors.warning, constants.colors.danger],
      fill: {
        type: 'gradient',
        gradient: {
          shade: constants.colors.axisColor,
          shadeIntensity: 0.6,
          opacityFrom: 0.5,
          opacityTo: 0.25,
          stops: [0, 95, 100],
        },
      },
      // grid: {
      //   borderColor: constants.colors.borderColor,
      //   strokeDashArray: 3,
      //   padding: {
      //     top: -20,
      //     bottom: -8,
      //     left: -10,
      //     right: 8,
      //   },
      // },
      xaxis: {
        categories: [],
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          style: {
            fontSize: '13px',
            colors: constants.colors.axisColor,
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
        },
        min: 0,
        // max: 50,
        tickAmount: 4,
      },
    };
  }
}
