
import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';
import moment from 'moment';
import MessagesStatisticTable from '@/components/MessagesStatisticTable.vue';
import ErrorResponse from '@/models/ErrorResponse';
import MessageQueue from '@/models/MessageQueue';
import MessageQueueFilter from '@/models/MessageQueueFilter';
import Page from '@/models/Page';
import { fetchMessageQueues } from '@/services/api-services';
import Pagination from '@/components/Pagination.vue';
import MessageStatisticFilterForm from '@/components/MessageStatisticFilterForm.vue';
import Config from '@/models/Config';

@Options({
  components: {
    MessageStatisticFilterForm,
    MessagesStatisticTable,
    Pagination,
  },
})
export default class MessageQueueStatistic extends Vue {
  store = useStore();

  pageMessegeQueue: Page<MessageQueue[]> = {
    totalItems: 0,
    items: [],
  };

  configId = '';

  from = moment().add(-10, 'days');

  to = moment();

  lastMessageFilter!: MessageQueueFilter;

  totalPages = 0;

  currentPage = 0;

  isLoading = false;

  serverErrors: ErrorResponse[] = [];

  async mounted(): Promise<void> {
    const { configs } = this.store.state;
    if (configs.length > 0) {
      const firstConfig = configs.find((config: Config) => config.status === 1);
      if (firstConfig) {
        this.configId = firstConfig.id;
        this.lastMessageFilter = {
          configId: firstConfig.id,
          from: this.from.utc().toDate(),
          to: this.to.utc().toDate(),
          skip: 0,
          take: 50,
          sortColumn: 'created',
          sortDirectionEnum: 0,
        };
        await this.loadData(this.lastMessageFilter);
      }
    }
  }

  async applyFilter(messageQueueFilter: MessageQueueFilter): Promise<void> {
    console.log(messageQueueFilter);
    this.lastMessageFilter = messageQueueFilter;
    this.currentPage = 0;
    await this.loadData(messageQueueFilter);
  }

  async setPage(pageNumber: number): Promise<void> {
    console.log(pageNumber);
    this.currentPage = pageNumber;
    const messageQueueFilter: MessageQueueFilter = {
      ...this.lastMessageFilter,
      skip: pageNumber * 50,
    };

    await this.loadData(messageQueueFilter);
  }

  async loadData(messageQueueFilter: MessageQueueFilter): Promise<void> {
    try {
      this.isLoading = true;
      this.serverErrors = [];
      const response = await fetchMessageQueues(messageQueueFilter);

      if (!response.isSuccess) {
        this.serverErrors = response.errors ? response.errors : [];
      } else {
        console.log(response);
        if (response.data) {
          this.totalPages = Math.ceil(response.data.totalItems / 50);
          this.pageMessegeQueue = response.data;
        }
      }
    } catch (ex) {
      this.serverErrors = [{
        code: 500,
        description: this.$t('common.serverError'),
      }];
    } finally {
      this.isLoading = false;
    }
  }

  formatDate(date: moment.Moment): string {
    return date.format('YYYY-MM-DD');
  }
}
