
import { Options, Vue } from 'vue-class-component';
import HtmlParams from '@/models/HtmlParams';

@Options({
  props: {
    token: String,
    htmlParamsConfig: String,
    messenger: Number,
  },
})
export default class CodeGenerator extends Vue {
  token!: string;

  htmlParamsConfig?: string;

  messenger!: number;

  code = '';

  htmlParams: HtmlParams = {
    hideAfterSuccess: false,
  };

  copyClipbord(): void {
    // Copy the text inside the text field
    navigator.clipboard.writeText(this.code);
  }

  mounted(): void {
    if (this.htmlParamsConfig) {
      const htmlParams = JSON.parse(this.htmlParamsConfig) as HtmlParams;
      this.setHtmlParams(htmlParams);
    }

    this.code = this.generateCode();
    console.log(this.code);
  }

  setHtmlParams(htmlParams: HtmlParams) {
    this.htmlParams = htmlParams;
  }

  onChange(): void {
    this.code = this.generateCode();
    const htmlParamsConfig = JSON.stringify(this.htmlParams);
    this.$emit('on-change', htmlParamsConfig);
  }

  generateCode(): string {
    let code = '<script1 src="https://infocodebot-cdn.website.yandexcloud.net/infocodebot.min.js"></script1>';
    code += `<script1 type="application/javascript">InfoCodeBotWidget.create('${this.token}', { `;
    code += this.generateParams().join(',');
    code += ' })</script1>';
    // do this stupid replace because eslint not allow use two script in one vue file even if script inside string.
    code = code.replaceAll('script1', 'script');
    return code;
  }

  generateParams(): string[] {
    const params = [];
    if (this.htmlParams) {
      if (this.htmlParams?.phoneElementId && this.htmlParams.phoneElementId.length > 0) {
        params.push(`inputElementId: '${this.htmlParams.phoneElementId}'`);
      }
      if (this.htmlParams?.inputElementName && this.htmlParams.inputElementName.length > 0) {
        params.push(`inputElementName: '${this.htmlParams.inputElementName}'`);
      }
      if (this.htmlParams?.submitButtonId && this.htmlParams.submitButtonId.length > 0) {
        params.push(`submitButtonId: '${this.htmlParams.submitButtonId}'`);
      }
      if (this.htmlParams?.sendButtonClasses && this.htmlParams.sendButtonClasses.length > 0) {
        params.push(`sendButtonClasses: '${this.htmlParams.sendButtonClasses}'`);
      }
      if (this.htmlParams?.qrImgClasses && this.htmlParams.qrImgClasses.length > 0) {
        params.push(`qrImgClasses: '${this.htmlParams.qrImgClasses}'`);
      }
      if (this.htmlParams?.pinClasses && this.htmlParams.pinClasses.length > 0) {
        params.push(`pinClasses: '${this.htmlParams.pinClasses}'`);
      }
      if (this.htmlParams?.goodCodeClasses && this.htmlParams.goodCodeClasses.length > 0) {
        params.push(`goodCodeClasses: '${this.htmlParams.goodCodeClasses}'`);
      }
      if (this.htmlParams?.wrongCodeClasses && this.htmlParams.wrongCodeClasses.length > 0) {
        params.push(`wrongCodeClasses: '${this.htmlParams.wrongCodeClasses}'`);
      }
      if (this.htmlParams?.lang && this.htmlParams.lang.length > 0) {
        params.push(`lang: '${this.htmlParams.lang}'`);
      }
      if (this.htmlParams?.afterSend && this.htmlParams.afterSend.length > 0) {
        params.push(`afterSend: () => { ${this.htmlParams.afterSend} }`);
      }
      if (this.htmlParams?.afterSuccess && this.htmlParams.afterSuccess.length > 0) {
        params.push(`afterSuccess: () => { ${this.htmlParams.afterSuccess} }`);
      }
      params.push(`hideAfterSuccess: ${this.htmlParams.hideAfterSuccess}`);
    }
    return params;
  }
}
