
import { Options, Vue } from 'vue-class-component';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

interface SmsAeroConfig {
  email: string;
  password: string;
  from: string;
}

@Options({
  props: {
    smsApiConfig: String,
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
    from: {
      required,
    },
  },
})
export default class SmsAeroForm extends Vue {
  smsApiConfig!: string;

  email = '';

  password = '';

  from = '';

  v$ = useVuelidate();

  mounted(): void {
    if (this.smsApiConfig) {
      const config = JSON.parse(this.smsApiConfig) as SmsAeroConfig;
      this.email = config.email;
      this.password = config.password;
      this.from = config.from;
    }
  }

  onChange(): void {
    const config: SmsAeroConfig = {
      email: this.email,
      password: this.password,
      from: this.from,
    };
    const configStr = JSON.stringify(config);
    this.$emit('on-change', configStr);
  }
}
