import { InjectionKey } from 'vue';
import { createStore, Store, useStore as baseUseStore } from 'vuex';
import AuthResponse from './models/AuthResponse';
import Config from './models/Config';

// define your typings for the store state
export interface State {
  accountId: string;
  name: string;
  token: string;
  refreshToken: string;
  balance: number;
  tariff: number;
  currency: number;
  language: string;
  configs: Config[];
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol('keyStore');

// Create a new store instance.
export const store = createStore<State>({
  state: {
    accountId: '',
    name: '',
    token: '',
    refreshToken: '',
    balance: 0,
    tariff: 0,
    currency: 2,
    language: '',
    configs: [],
  },
  mutations: {
    initialiseStore(state: State): void {
      // Check if the ID exists
      const storeInLocalStorage = localStorage.getItem('store');
      if (storeInLocalStorage) {
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(state, JSON.parse(storeInLocalStorage)),
        );
      }
    },

    setAuth(state: State, payload: AuthResponse): void {
      state.accountId = payload.account.id;
      state.token = payload.authToken;
      state.refreshToken = payload.refreshToken;
      state.name = payload.account.name;
      state.balance = payload.account.balance;
      state.tariff = payload.account.tariff;
      state.currency = payload.account.currency;
      state.language = payload.account.language;
    },

    setLang(state: State, lang: string): void {
      state.language = lang;
    },

    clearAuth(state: State): void {
      state.token = '';
      state.refreshToken = '';
      state.name = '';
      state.balance = 0;
      state.tariff = 0;
      state.currency = 2;
      state.language = 'en';
      state.configs = [];
      localStorage.removeItem('store');
    },

    setConfigs(state: State, payload: Config[]): void {
      state.configs = payload;
    },
  },
});

// Subscribe to store updates
store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  localStorage.setItem('store', JSON.stringify(state));
});

// define your own `useStore` composition function
export function useStore() {
  return baseUseStore(key);
}
