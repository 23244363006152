
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class Menu extends Vue {
  isActive(menuItem: string): boolean {
    if (menuItem === 'dashboard' && this.$route.path === '/') {
      return true;
    }

    return this.$route.path.indexOf(menuItem) >= 0;
  }
}
