import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import VueApexCharts from 'vue3-apexcharts';
// @ ts-nocheck
// import vClickOutside from 'click-outside-vue3';
import filters from '@/utils/filters';
import utils from '@/utils/utils';
import messages from './utils/translate';
import App from './App.vue';
import router from './router';
import '@/assets/css/core.css';
import '@/assets/css/theme-default.css';
import '@/assets/css/demo.css';
import '@/assets/fonts/boxicons.css';
import { store, key } from './store';

// 2. Create i18n instance with options
const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
});

const app = createApp(App)
  .use(i18n)
  .use(router)
  .use(store, key)
  // .use(vClickOutside)
  .use(VueApexCharts);

app.config.globalProperties.$filters = filters;
app.config.globalProperties.$utils = utils;

app.mount('#app');
