
import { Options, Vue } from 'vue-class-component';
import TarifCard from '@/components/TarifCard.vue';
import YMoneyButton from '@/components/YMoneyButton.vue';

@Options({
  components: {
    TarifCard,
    YMoneyButton,
  },
})
export default class Payments extends Vue {
}
