
import { Options, Vue } from 'vue-class-component';
import Config from '@/models/Config';
import ConfigRequest from '@/models/ConfigRequest';
import BaseResponse from '@/models/BaseResponse';
import ErrorResponse from '@/models/ErrorResponse';
import RestApiForm from '@/components/RestApiForm.vue';
import TwilioForm from '@/components/TwilioForm.vue';
import SmsAeroForm from '@/components/SmsAeroForm.vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { createConfig, getConfig, updateConfig } from '@/services/api-services';

@Options({
  components: {
    RestApiForm,
    TwilioForm,
    SmsAeroForm,
  },
  validations: {
    sender: {
      required,
    },
    messenger: {
      required,
    },
    smsApi: {
      required,
    },
  },
})
export default class ConfigForm extends Vue {
  id = '';

  type = 'new';

  sender = '';

  messenger = 1;

  smsApi = 0;

  private smsApiConfig!: string;

  private smsApiConfigs: string[] = [];

  isCreate = true;

  isLoading = false;

  serverErrors: ErrorResponse[] = [];

  hints = [
    'config.smsApiNoneDescription',
    'config.smsApiRestDescription',
    'config.smsApiTwilioDescription',
    'config.smsApiSmsAeroDescription',
  ];

  v$ = useVuelidate();

  async mounted(): Promise<void> {
    this.type = this.$route.params.type as string;
    this.id = this.$route.params.id as string;
    this.isCreate = this.type === 'new';
    for (let i = 0; i < 5; i++) {
      this.smsApiConfigs[i] = '';
    }

    if (!this.isCreate) {
      const response = await getConfig(this.id);
      console.log(response);
      if (response.isSuccess && response.data) {
        this.messenger = response.data.messenger;
        this.sender = response.data.sender;
        this.smsApi = response.data.smsApi;
        if (response.data.smsApiConfig) {
          this.smsApiConfig = response.data.smsApiConfig;
        }
      }
    }

    this.smsApiConfigs[this.smsApi] = this.smsApiConfig;
  }

  onChange(configStr: string): void {
    console.log(configStr);
    this.smsApiConfig = configStr;
    this.smsApiConfigs[this.getSmsApi()] = this.smsApiConfig;
    console.log(this.smsApiConfigs);
  }

  onSmsApiSelected(event: any): void {
    console.log(this.smsApiConfigs);
    console.log(this.smsApiConfigs[this.getSmsApi()]);
    this.smsApiConfig = this.smsApiConfigs[this.getSmsApi()];
  }

  getSmsApi(): number {
    return typeof (this.smsApi) !== 'number' ? parseInt(this.smsApi, 10) : this.smsApi;
  }

  async save(): Promise<void> {
    const configRequest: ConfigRequest = {
      id: this.id,
      status: 1,
      sender: this.sender,
      messenger: this.messenger,
      smsApi: this.smsApi,
      smsApiConfig: this.smsApiConfig,
    };

    try {
      this.isLoading = true;
      this.serverErrors = [];
      let response: BaseResponse<Config>;
      if (this.isCreate) {
        response = await createConfig(configRequest);
      } else {
        response = await updateConfig(configRequest);
      }

      if (response.isSuccess && response.data) {
        console.log(response.data);
        this.$router.push('/configs');
      } else if (response.errors) {
        this.serverErrors = response.errors;
      }
    } catch (ex) {
      this.serverErrors = [{
        code: 500,
        description: this.$t('common.serverError'),
      }];
    } finally {
      this.isLoading = false;
    }
  }
}
