
import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';
import ChangeLanguageRequest from '@/models/ChangeLanuageRequest';
import { changeLanguage } from '@/services/api-services';

@Options({})
export default class LangSelector extends Vue {
  isOpenSelectLang = false;

  store = useStore();

  getLangImg(): string {
    if (this.$i18n.locale === 'ru') {
      return require('../assets/img/flags/ru.png');
    }
    return require('../assets/img/flags/en.png');
  }

  async selectLang(lang: string): Promise<void> {
    this.$i18n.locale = lang;
    this.store.commit('setLang', lang);
    try {
      const changeLanguageReqeust: ChangeLanguageRequest = {
        language: lang,
      };
      const response = await changeLanguage(this.store.state.accountId, changeLanguageReqeust);
    } catch (ex) {
      console.error(ex);
    }

    this.isOpenSelectLang = false;
  }
}
