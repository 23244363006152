
import LangSelector from '@/components/LangSelector.vue';
import ChangePasswordRequest from '@/models/ChangePasswordRequest';
import ErrorResponse from '@/models/ErrorResponse';
import { Options, Vue } from 'vue-class-component';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { changePassword } from '@/services/api-services';

@Options({
  components: {
    LangSelector,
  },
  validations: {
    email: {
      required,
      email,
    },
  },
})
export default class ForgotPassword extends Vue {
  isSuccessChange = false;

  isLoading = false;

  email = '';

  serverErrors: ErrorResponse[] = [];

  v$ = useVuelidate();

  async changePassword(): Promise<void> {
    const changePasswordReqeust: ChangePasswordRequest = {
      email: this.email as string,
    };

    try {
      this.isLoading = true;
      this.serverErrors = [];
      const response = await changePassword(changePasswordReqeust);
      if (response.isSuccess && response.data) {
        console.log(response.data);

        this.isSuccessChange = response.data;
      } else if (response.errors) {
        this.serverErrors = response.errors;
      }
    } catch (ex) {
      this.serverErrors = [{
        code: 500,
        description: this.$t('common.serverError'),
      }];
    } finally {
      this.isLoading = false;
    }
  }
}
