
import { Options, Vue } from 'vue-class-component';
import constants from '@/utils/constants';
import { useStore } from '@/store';
import ErrorResponse from '@/models/ErrorResponse';
import { getTotalStatistic } from '@/services/api-services';
import TotalStatistic from '@/models/TotalStatistic';

@Options({
  components: {
  },
  props: {
  },
})
export default class MessagesStatisticCard extends Vue {
  messageStatisticsOptions: any;

  store = useStore();

  messageStatisticsSeries: number[] = [];

  messageStatisticsLabels: string[] = [];

  totalStatistic: TotalStatistic = {
    countTotal: 0,
    countByMessenger: 0,
    countBySms: 0,
    countFailed: 0,
  };

  isLoading = false;

  serverErrors: ErrorResponse[] = [];

  created(): void {
    this.buildChart();
  }

  async mounted(): Promise<void> {
    this.$nextTick(() => {
      window.dispatchEvent(new Event('resize'));
    });
    await this.loadData();
  }

  async loadData() : Promise<void> {
    try {
      this.isLoading = true;
      this.serverErrors = [];
      const response = await getTotalStatistic();

      if (!response.isSuccess) {
        this.serverErrors = response.errors ? response.errors : [];
      } else {
        console.log(response);
        if (response.data) {
          this.totalStatistic = response.data;
          this.messageStatisticsSeries = [
            response.data.countByMessenger,
            response.data.countBySms,
            response.data.countFailed,
          ];
        }
      }
    } catch (ex) {
      this.serverErrors = [{
        code: 500,
        description: this.$t('common.serverError'),
      }];
    } finally {
      this.isLoading = false;
    }
  }

  buildChart(): void {
    this.messageStatisticsOptions = {
      labels: ['Send by messenger', 'Send by SMS', 'Sening errors'],
      colors: [constants.colors.primary, constants.colors.warning, constants.colors.danger],
      stroke: {
        width: 5,
        colors: constants.colors.white,
      },
      dataLabels: {
        enabled: false,
        formatter: (val: string, opt: any): string => `${parseInt(val, 10)}%`,
      },
      legend: {
        show: false,
      },
      grid: {
        padding: {
          top: 0,
          bottom: 0,
          right: 15,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '75%',
            labels: {
              show: true,
              value: {
                fontSize: '1.5rem',
                fontFamily: 'Public Sans',
                color: constants.colors.headingColor,
                offsetY: -15,
                formatter: (val: string): string => `${parseInt(val, 10)}%`,
              },
              name: {
                offsetY: 20,
                fontFamily: 'Public Sans',
              },
              total: {
                show: true,
                fontSize: '0.8125rem',
                color: constants.colors.axisColor,
                label: 'Total',
                formatter: (total: any): string => {
                  console.log(total);
                  return total.config.series.reduce((partialSum: number, a: number): number => partialSum + a, 0);
                },
              },
            },
          },
        },
      },
    };
  }
}
