
import Config from '@/models/Config';
import ConfigRequest from '@/models/ConfigRequest';
import TestMessageForm from '@/components/TestMessageForm.vue';
import CodeGenerator from '@/components/CodeGenerator.vue';
import { Options, Vue } from 'vue-class-component';
import { updateConfig } from '@/services/api-services';

@Options({
  components: {
    TestMessageForm,
    CodeGenerator,
  },
  props: {
    config: Object,
  },
})
export default class ConfigCard extends Vue {
  config!: Config

  smsApiConfigKeys = [['']];

  selectedTab = 'properties';

  mounted(): void {
    if (this.config.smsApiConfig) {
      const smsApiConfig = JSON.parse(this.config.smsApiConfig);
      this.smsApiConfigKeys = Object.entries(smsApiConfig);
      console.log(this.smsApiConfigKeys);
    }
  }

  editConfig(id: string): void {
    this.$router.push(`/configs/form/edit/${id}`);
  }

  async deleteConfig(config: Config): Promise<void> {
    await this.updateStatusConfig(config, 3);
  }

  async restoreConfig(config: Config): Promise<void> {
    await this.updateStatusConfig(config, 1);
  }

  async updateHtmlParamsConfig(config: Config, htmlParamsConfig: string): Promise<void> {
    const configRequest: ConfigRequest = {
      ...config,
      htmlParamsConfig,
    };
    console.log(configRequest);
    await this.updateConfig(configRequest);
  }

  async updateStatusConfig(config: Config, status: number): Promise<void> {
    const configRequest: ConfigRequest = {
      ...config,
      status,
    };

    this.updateConfig(configRequest);
  }

  async updateConfig(configRequest: ConfigRequest): Promise<void> {
    try {
      const response = await updateConfig(configRequest);
      if (response.isSuccess && response.data) {
        console.log(response.data);
        this.$emit('updated', response.data);
        this.config.status = response.data.status;
      } else if (response.errors) {
        // this.serverErrors = response.errors;
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  toogleTab(val: string): void {
    this.selectedTab = val;
  }

  isActiveTab(val: string): boolean {
    return this.selectedTab === val;
  }
}
