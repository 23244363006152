
import { Options, Vue } from 'vue-class-component';
import { useStore } from '@/store';

@Options({
  components: {},
  props: {},
})
export default class BalanceSmallCard extends Vue {
  store = useStore();
}
