// 1. Ready translated locale messages
// The structure of the locale message is the hierarchical object structure with each
// locale as the top property
const messages = {
  en: {
    auth: {
      signIn: 'Sign in',
      signUp: 'Sign up',
      welcome: 'Welcome to InfoCodeBot!',
      desc: 'Please sign-in to your account and start sending your codes',
      registerTitle: 'Adventure starts here',
      registerDesc: 'Create your account and start sending codes through messengers',
      name: 'Name',
      namePlaceholder: 'Enter your name',
      email: 'Email',
      emailPlaceholder: 'Enter your email',
      emailNotValid: 'Please enter a valid email address',
      password: 'Password',
      forgotPassword: 'Forgot Password?',
      forgotPasswordDesc: 'Enter your email and we\'ll send you instructions to reset your password',
      forgotPasswordButton: 'Send Reset Link',
      backToLogin: 'Back to login',
      agree: 'I agree to',
      privacyPolicy: 'privacy policy & terms',
      newUser: 'New on our platform? ',
      alreadyHave: 'Already have an account? ',
      createAccount: 'Create an account',
      changePasswordSuccessRequest: 'The password change request has been successfully sent, follow the instructions in the email',
      confrimSuccess: 'Email confirmed',
      goToLogin: 'You can go to sign in page',
      newPassword: 'New password',
      setNewPassword: 'Set new password',
      logout: 'Log Out',
    },
    common: {
      dashboard: 'Dashboard',
      edit: 'Edit',
      delete: 'Delete',
      restore: 'Restore',
      save: 'Save',
      send: 'Send',
      configTitle: 'Configs',
      serverError: 'An error has occurred, please try again later',
      billing: 'Billing',
      messageQueueStatistic: 'Statistics',
      documentation: 'Documentation',
    },
    dashboard: {
      welcome: 'Congratulation {name}!',
      firstDescription: 'Statistics on sent messages will be displayed here. But first you need to create configurations and start sending messages',
      goToConfiguration: 'Go to configs',
    },
    statistic: {
      monthlyTitle: 'Monthly statistics',
      balanceTitle: 'Balance',
      tarifTitle: 'Tarif',
      byDayTitle: 'Sent messages by day',
      profitableTitle: 'Profitable (money saved)',
      totalMessages: 'Total send',
      sendByMessenger: 'Send by messenger',
      sendBySms: 'Send by SMS',
      sendErrors: 'Failed sending',
      phone: 'Phone',
      message: 'Message',
      status: 'Status',
      errorMessage: 'Error message',
      created: 'Created',
      updated: 'Updated',
      empty: 'There are no messages that meet the criteria',
      formTitle: 'Messages filter',
      from: 'From',
      to: 'To',
      moneyPerWeek: 'Saved money per week',
      selectStatus: '-- Select status --',
      selectConfig: '-- Select config --',
      applyFilter: 'Apply filter',
    },
    message: {
      config: 'Config',
      status: {
        created: 'Created',
        inQueue: 'In queue',
        sendMessenger: 'Send by messenger',
        sendSmsApi: 'Send by SMS',
        failed: 'Failed',
      },
    },
    payment: {
      title: 'Pay for messages',
      articul: 'Send a message',
      count: 'Count',
      aboutCustomer: 'About customer',
      phoneOrEmail: 'Phone or email for receipt',
      pay: 'Pay',
      yourTarif: 'Your tarif is',
      freeTarif: 'At the moment, the project is in beta testing, so we do not charge money for use. However, this may change in the future.',
      nextTarif: 'You need to send more than {count} to upgrade to the next tariff',
      donate: 'You can help the development of our service by donating any amount',
    },
    config: {
      create: 'Create new configuration',
      createNew: 'Create new configuration',
      edit: 'Edit configuration',
      empty: 'Configurations is empty',
      token: 'Token',
      tokenPlaceholder: 'Token will generate automatically',
      messenger: 'Messenger',
      messengerPlaceholder: 'Choose messenger',
      senderName: 'Sender name',
      smsApi: 'SMS API',
      smsApiPlaceholder: 'Choose SMS API',
      smsApiNone: 'No SMS API',
      smsApiNoneDescription: 'In this case, the message will be sent only to the messenger. If a person is not registered in the messenger, he will not receive the message. In order for the message to arrive, use one of the proposed SMS sending services',
      smsApiRestDescription: 'If the phone is not registered in the messenger, this method will be called over HTTP, you can send the phone, the text in the URL parameters in the format #123phone&#125, &#123text&#125. For example: https://sendsms.com/send.php?phone=&#123phone&#125&text=&#123text&#125',
      smsApiTwilioDescription: 'If the phone is not registered in the messenger, the Twilio service will be called to send SMS. You need to register an account on <a _target="blank" href="https://www.twilio.com /">https://www.twilio.com /</a> and fill in the form received through your personal account, Account SID, Auth Token',
      smsApiSmsAeroDescription: 'If the phone is not registered in the messenger, the Aero Sms service will be called to send SMS. You need to register an account on <a _target="blank" href="https://smsaero.ru /">https://smsaero.ru /</a> and fill in the Email and account password form',
      status: 'Status',
      actions: 'Actions',
      properties: 'Properteis',
      noProperties: 'No properties for this configuration',
      helpTitle: 'Hint',
      api: 'API',
      testForm: 'Test form',
      isNotExistsOnBotMock: 'Imitate phone not exists on Bot (force send SMS)',
      settings: 'Settings',
      code: 'HTML Code',
      phoneElementId: 'Phone field Id',
      phoneElementName: 'Phone field Name',
      submitButtonId: 'Submit button Id',
      sendButtonClasses: 'CSS classes for Send button',
      qrImgClasses: 'CSS classes QR image',
      pinClasses: 'CSS classes for pin filed',
      goodCodeClasses: 'CSS classes for "Good code" text',
      wrongCodeClasses: 'CSS classes for "Wrong code" text',
      lang: 'Language',
      hideAfterSuccess: 'Hide form after success input code',
      afterSend: 'After send code script',
      afterSuccess: 'After success verify script',
      copyToClipbord: 'Copy to clipboard',
      phone: 'Phone',
      message: 'Message',
      messageStatus: 'Message status',
      testFormDescription: 'You can test sending messages through this form. Enter phone and message and click Send',
      testTelegramBotDescription: 'To receive the message in telegram, go to the bot by the link <a _target="blank" href="https://t.me/infocodebot">t.me/infocodebot</a> or scan the QR code',
      testTelegramBotDescription2: 'Click the "Start" and "Share phone" button in the bot',
      htmlCodeTitle: 'Copy and paste this code into your html page between the <body></body> tags',
      htmlCodeExample: 'How it will display',
      twilio: {
        accountSid: 'Account Sid',
        accountSidPlaceholder: 'Twilio account Sid',
        authToken: 'Auth token',
        authTokenPlaceholder: 'Twilio auth token',
        phoneNumber: 'Sender phone number',
        phoneNumberPlaceholder: 'Sender phone number',
      },
      smsAero: {
        email: 'Email',
        emailPlaceholder: 'SmsAero account Email',
        password: 'Password',
        passwordPlaceholder: 'SmsAero account password',
        from: 'Sender name',
        fromPlaceholder: 'Sender name',
      },
    },
    validate: {
      name: {
        required: 'Name is required',
      },
      email: {
        email: 'Value is not a valid email address',
        required: 'Email is required',
      },
      terms: {
        checked: 'You should agree with terms',
      },
      password: {
        required: 'Password is required',
        minLength: 'Password should be at least 8 characters long',
      },
    },
  },
  ru: {
    auth: {
      signIn: 'Войти',
      signUp: 'Зарегистрироваться',
      welcome: 'Вас приветсвует InfoCodeBot!',
      desc: 'Войдите или зарегистрируйтесь и начните отправлять коды через бота',
      registerTitle: 'Здесь все начинается',
      registerDesc: 'Создайте аккаунт и начните отправлять коды через месенджеры',
      name: 'Ваше имя',
      namePlaceholder: 'Введите ваше имя',
      email: 'Email',
      emailPlaceholder: 'Введите ваш email',
      emailNotValid: 'Пожалуйста введите корректный email',
      password: 'Пароль',
      forgotPassword: 'Забыли пароль?',
      forgotPasswordDesc: 'Введите ваш email и следуйте инструкциям в письме, чтобы сменить пароль',
      forgotPasswordButton: 'Сбросить пароль',
      backToLogin: 'Вернуться к авторизации',
      newUser: 'Еще нет аккаунта? ',
      agree: 'Я прочитал и согласен с ',
      privacyPolicy: 'терминами и определениями',
      alreadyHave: 'Уже есть аккаунт? ',
      createAccount: 'Зарегистрироваться',
      changePasswordSuccessRequest: 'Запрос на смену пароля успешно отправлен, следуйте инструкциям в письме',
      confrimSuccess: 'Email подтвержден',
      goToLogin: 'Вы можете перейти на страницу авторизации',
      newPassword: 'Новый пароль',
      setNewPassword: 'Установить пароль',
      logout: 'Выход',
    },
    common: {
      dashboard: 'Главная',
      edit: 'Редактировать',
      delete: 'Удалить',
      restore: 'Восстановить',
      save: 'Сохранить',
      send: 'Отправить',
      configTitle: 'Конфигурации',
      serverError: 'Произошла ошибка, попробуйте еще раз позже',
      billing: 'Платежи',
      messageQueueStatistic: 'Статистика',
      documentation: 'Документация',
    },
    dashboard: {
      welcome: 'Поздравляем {name}!',
      firstDescription: 'Здесь будет отображаться статистика по отправленным сообщениям. Но для начала необходимо создать конфигурации и начать отправлять сообщения',
      goToConfiguration: 'Перейти к конфигурациям',
    },
    payment: {
      title: 'Оплата за сообщения',
      articul: 'Отправка сообщения',
      count: 'Количество',
      aboutCustomer: 'О покупателе',
      phoneOrEmail: 'Телефон или Email для чека',
      pay: 'Заплатить',
      yourTarif: 'Ваш тариф',
      nextTarif: 'Вам нужно отправить более {count}, чтобы перейти на следующий тариф',
      freeTarif: 'На данный момент проект находится в стадии бета-тестирования, поэтому мы не берем денег за использование. Однако это может измениться в будущем',
      donate: 'Вы можете помочь развитию нашего сервиса пожертвовал любую сумму',
    },
    statistic: {
      monthlyTitle: 'Месячная статистика',
      byDayTitle: 'Отправлено сообщений по дням',
      profitableTitle: 'Выгода (сэкономлено средств)',
      balanceTitle: 'Баланс',
      tarifTitle: 'Тариф',
      totalMessages: 'Всего отправлено',
      sendByMessenger: 'Отправлено через мессенджер',
      sendBySms: 'Отправлено СМС',
      sendErrors: 'Ошибки отправки',
      phone: 'Телефон',
      message: 'Сообщение',
      status: 'Статус',
      errorMessage: 'Сообщение об ошибке',
      created: 'Создано',
      updated: 'Обновлено',
      empty: 'Нет сообщений удовлетворяющих критериям',
      formTitle: 'Фильтр сообщений',
      from: 'От',
      to: 'До',
      moneyPerWeek: 'Экономия средств за неделю',
      selectStatus: '-- Выбрать статус --',
      selectConfig: '-- Выбрать конфигурацию --',
      applyFilter: 'Применить фильтр',
    },
    message: {
      config: 'Конфигурация',
      status: {
        created: 'Создано',
        inQueue: 'В очереди',
        sendMessenger: 'Отправлено через месенджер',
        sendSmsApi: 'Отправлено через СМС',
        failed: 'Ошибка',
      },
    },
    config: {
      create: 'Создать новую конфигрурацию',
      createNew: 'Создание новой конфигрурации',
      edit: 'Редактирование конфигрурации',
      empty: 'Нет созданных конфиграций',
      token: 'Токен',
      tokenPlaceholder: 'Токен будет сгенерирован автоматически',
      messenger: 'Месенджер',
      messengerPlaceholder: 'Выбирете месенджер',
      senderName: 'Имя отправителя',
      smsApi: 'СМС сервис',
      smsApiPlaceholder: 'Выбирете СМС сервис',
      smsApiNone: 'Без СМС',
      smsApiNoneDescription: 'В данном случае будет отправлено сообщение только в мессенджер. Если человек не зарегистрирован в мессенджере, он не получит сообщение. Для того чтобы сообщение пришло используйте один из предложенных сервисов отправки СМС',
      smsApiRestDescription: 'В случае если телефон не зарегистрирован в мессенджере, будет вызван данный метод по HTTP, вы можете передать телефон, текст в параметрах URL в формате #123phone&#125, &#123text&#125. Например: https://sendsms.com/send.php?phone=&#123phone&#125&text=&#123text&#125',
      smsApiTwilioDescription: 'В случае если телефон не зарегистрирован в мессенджере, будет вызван сервис Twilio для отправки SMS. Вам необходимо зарегистрировать аккаунт на <a _target="blank" href="https://www.twilio.com/">https://www.twilio.com/</a> и заполнить в форме, полученные через личный кабинет, Account SID, Auth Token',
      smsApiSmsAeroDescription: 'В случае если телефон не зарегистрирован в мессенджере, будет вызван сервис Sms Aero для отправки SMS. Вам необходимо зарегистрировать аккаунт на <a _target="blank" href="https://smsaero.ru/">https://smsaero.ru/</a> и заполнить в форме Email и пароль от аккаунта',
      status: 'Статус',
      actions: 'Действия',
      properties: 'Свойства',
      noProperties: 'Нет свойств для данной конфигурации',
      helpTitle: 'Подсказка',
      api: 'API',
      testForm: 'Тестовая форма',
      isNotExistsOnBotMock: 'Имитация отсутствия телефона в боте (принудительная отправка SMS)',
      settings: 'Настройки',
      code: 'HTML код',
      phoneElementId: 'Id поля ввода телефона',
      phoneElementName: 'Name поля ввода телефона',
      submitButtonId: 'Id кнопки отправки формы',
      sendButtonClasses: 'CSS классы для кнопки отправить',
      qrImgClasses: 'CSS классы для изображения QR-кода',
      pinClasses: 'CSS классы для полей ввода кода',
      goodCodeClasses: 'CSS классы для текста "Верный код"',
      wrongCodeClasses: 'CSS классы для текста "Не верный код"',
      afterSend: 'Скрипт после отправки кода',
      afterSuccess: 'Скрипт после успешной проверки кода',
      lang: 'Язык',
      hideAfterSuccess: 'Скрывать формку после успешного ввода кода',
      copyToClipbord: 'Скопировать в буффер',
      phone: 'Телефон',
      message: 'Сообщение',
      messageStatus: 'Статус сообщения',
      testFormDescription: 'Вы можете протестировать отправку сообщений через эту форму для этого введите телефон и сообщение',
      testTelegramBotDescription: 'Чтобы сообщение пришло в телеграмм перейдите в бот по ссылке <a _target="blank" href="https://t.me/infocodebot">t.me/infocodebot</a> или отсканируйте QR-код',
      testTelegramBotDescription2: 'Нажмите в боте кнопку "Start" и "Поделиться телефоном"',
      htmlCodeTitle: 'Скопируйте и вставьте этот код на свою html страницу между тегами <body></body>',
      htmlCodeExample: 'Как это будет отображаться',
      twilio: {
        accountSid: 'Account Sid',
        accountSidPlaceholder: 'Twilio account Sid',
        authToken: 'Auth token',
        authTokenPlaceholder: 'Twilio auth token',
        phoneNumber: 'Номер телефона отправителя',
        phoneNumberPlaceholder: 'Номер телефона отправителя',
      },
      smsAero: {
        email: 'Email',
        emailPlaceholder: 'Email от аккаунта SmsAero',
        password: 'Пароль',
        passwordPlaceholder: 'Пароль от аккаунта SmsAero',
        from: 'Имя отправителя',
        fromPlaceholder: 'Имя отправителя СМС',
      },
    },
    validate: {
      name: {
        required: 'Имя обязателено для заполнения',
      },
      email: {
        email: 'Введенное значение не является корректным email',
        required: 'Email обязателен для заполнения',
      },
      terms: {
        checked: 'Вы должны принять термины и определения',
      },
      password: {
        required: 'Пароль обязателен для заполнения',
        minLength: 'Пароль должен быть не менее 8 символов',
      },
    },
  },
};

export default messages;
