
import { Options, Vue } from 'vue-class-component';
import { v4 as uuid } from 'uuid';
import { useStore } from '@/store';
import Config from '@/models/Config';
import ConfigCard from '@/components/ConfigCard.vue';
import { fetchConfigs } from '@/services/api-services';

@Options({
  components: {
    ConfigCard,
  },
})
export default class Configs extends Vue {
  store = useStore();

  configs: Config[] = []

  async mounted(): Promise<void> {
    await this.loadConfigs();
  }

  async loadConfigs(): Promise<void> {
    const response = await fetchConfigs();
    if (response.isSuccess && response.data) {
      this.configs = response.data;
      this.store.commit('setConfigs', response.data);
    }
  }

  createNewConfig(): void {
    const newId = uuid();
    this.$router.push(`/configs/form/new/${newId}`);
  }

  onUpdateConfig(config: Config): void {
    console.log(config);
  }
}
