
import NewPasswordRequest from '@/models/NewPasswordRequest';
import LangSelector from '@/components/LangSelector.vue';
import ErrorResponse from '@/models/ErrorResponse';
import { useStore } from '@/store';
import { Options, Vue } from 'vue-class-component';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { newPassword } from '@/services/api-services';

@Options({
  components: {
    LangSelector,
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
    },
  },
})
export default class NewPassword extends Vue {
  isLoading = false;

  password = '';

  serverErrors: ErrorResponse[] = [];

  v$ = useVuelidate();

  store = useStore();

  async setNewPassword(): Promise<void> {
    const newPasswordReqeust: NewPasswordRequest = {
      email: this.$route.params.email as string,
      hash: this.$route.params.hash as string,
      newPassword: this.password as string,
    };

    try {
      this.isLoading = true;
      this.serverErrors = [];
      const response = await newPassword(newPasswordReqeust);
      if (response.isSuccess && response.data) {
        console.log(response.data);

        this.store.commit('setAuth', response.data);
        this.$router.push({ name: 'Dashboard' });
      } else if (response.errors) {
        this.serverErrors = response.errors;
      }
    } catch (ex) {
      this.serverErrors = [{
        code: 500,
        description: this.$t('common.serverError'),
      }];
    } finally {
      this.isLoading = false;
    }
  }
}
