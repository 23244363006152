
import { Options, Vue } from 'vue-class-component';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

interface TwilioConfig {
  accountSid: string;
  authToken: string;
  phoneNumber: string;
}

@Options({
  props: {
    smsApiConfig: String,
  },
  validations: {
    accountSid: {
      required,
    },
    authToken: {
      required,
    },
    phoneNumber: {
      required,
    },
  },
})
export default class TwilioForm extends Vue {
  smsApiConfig!: string;

  accountSid = '';

  authToken = '';

  phoneNumber = '';

  v$ = useVuelidate();

  mounted(): void {
    if (this.smsApiConfig) {
      const config = JSON.parse(this.smsApiConfig) as TwilioConfig;
      this.accountSid = config.accountSid;
      this.authToken = config.authToken;
      this.phoneNumber = config.phoneNumber;
    }
  }

  onChange(): void {
    const config: TwilioConfig = {
      accountSid: this.accountSid,
      authToken: this.authToken,
      phoneNumber: this.phoneNumber,
    };
    const configStr = JSON.stringify(config);
    this.$emit('on-change', configStr);
  }
}
